import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { mq } from 'helpers/stylehelpers';

import logo from '../images/logo.png';

const Holder = styled.div`
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
`;

const Logo = () => {
    return (
        <Holder>
            <Link to="/">
                <img src={logo} />
            </Link>
        </Holder>
    );
};

export default Logo;
