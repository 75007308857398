import React from 'react';

import { Highlight, P, Headline } from 'ui-kit/typography';
import Section from 'ui-kit/section';
import Textlink from 'ui-kit/textlink';

import Layout from '../components/layout';
import Logo from '../components/logo';

const DatenschutzPage = ({ data }) => {
    return (
        <Layout description="Datenschutz" title="Datenschutz">
            <Logo />
            <Section bg="white" container="l">
                <Headline level="h2">Datenschutzerklärung</Headline>
                <P gap="m">
                    Der Schutz Ihrer Privatsphäre ist uns wichtig, weshalb wir Sie darüber
                    informieren möchten, welche Maßnahmen und Grundsätze bei der Datenverarbeitung
                    dieser Website angewendet werden.
                </P>
                <P gap="m">
                    Unterschieden wird hierbei zwischen Zugriffsdaten und personenbezogenen Daten.
                    Zugriffsdaten werden bei der allgemeinen Nutzung unserer Website automatisch
                    erhoben und gespeichert, wohingegen personenbezogene Daten nur auf freiwilliger
                    Basis und mit Ihrer Erlaubnis gespeichert und verarbeitet werden.
                </P>
                <P gap="xxl">
                    Mit der Zustimmung zu der nachfolgenden Datenschutzerklärung willigen Sie
                    gegenüber der Südbayerische Fleischwaren GmbH in die Erhebung, Verarbeitung und
                    Nutzung Ihrer personenbezogenen Daten unter Beachtung der Datenschutzgesetze und
                    den nachfolgenden Bestimmungen ein.
                </P>
                <Headline level="h3">1. Verantwortliche Stelle</Headline>
                <P gap="xxl">
                    Falls Sie der Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch die
                    Südbayerische Fleischwaren GmbH entsprechend der nachfolgenden
                    Datenschutzbestimmungen insgesamt oder für einzelne Maßnahmen widersprechen
                    wollen, richten Sie Ihren Widerspruch bitte per E-Mail oder Brief an:
                </P>
                <P gap="m">
                    <Highlight>Südbayerische Fleischwaren GmbH </Highlight> <br />
                    Robert-Bosch-Straße 13 <br />
                    85053 Ingolstadt{' '}
                </P>
                <P gap="xxl">
                    E-Mail:{' '}
                    <Textlink
                        to="email:info@suedbayerische-fleischwaren.de"
                        gap="m"
                        display="inline-block"
                    >
                        info@suedbayerische-fleischwaren.de
                    </Textlink>
                </P>
                <Headline level="h3">2. Personenbezogene Daten</Headline>
                <P gap="m">
                    Personenbezogene Daten sind Angaben, die eine Identifizierung Ihrer Person
                    ermöglichen. Typischerweise ist das Ihr Name und Ihre Anschrift. Unsere Website
                    kann grundsätzlich ohne Preisgabe personenbezogener Daten genutzt werden.
                </P>
                <P gap="m">
                    Personenbezogene Daten werden auf unserer Website nur dann und insoweit erhoben
                    und genutzt, wie sie für die Ausführung der damit verbundenen Dienste
                    erforderlich sind. Mit dem Ausfüllen der entsprechend angebotenen Eingabefelder
                    erteilen Sie uns automatisch die Erlaubnis zur Speicherung, Verarbeitung und
                    Nutzung Ihrer personenbezogenen Daten. Die übermittelten Daten und Informationen
                    werden im Rahmen unseres Kundenservices nur an Firmenangehörige oder von uns
                    eingeschaltete Unternehmen weitergeleitet, die uns bei der Beantwortung Ihrer
                    Fragen und Anliegen unterstützen. Eine Weitergabe personenbezogener Daten an
                    sonstige Dritte ist ausgeschlossen. Ein Verkauf oder eine Vermietung von Daten
                    findet nicht statt.
                </P>
                <P gap="m">
                    Eine Löschung Ihrer personenbezogenen Daten erfolgt, sofern gesetzliche
                    Aufbewahrungspflichten dem nicht entgegenstehen und wenn Sie einen
                    Löschungsanspruch geltend gemacht haben, wenn die Daten zur Erfüllung des mit
                    der Speicherung verfolgten Zwecks nicht mehr erforderlich sind oder wenn ihre
                    Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.
                </P>
                <P gap="xxl">Bereiche in denen personenbezogene Daten abgefragt werden: Keine</P>
                <Headline level="h3">3. Cookies</Headline>
                <P gap="m">
                    Zum Besuch unserer Website ist das Akzeptieren von Cookies keine Voraussetzung.
                    Wir weisen jedoch darauf hin, dass durch eine Deaktivierung von Cookies evtl.
                    nicht sämtliche Funktionen dieser Website in vollem Umfang genutzt werden
                    können.
                </P>
                <P gap="m">
                    Cookies sind kleine Dateien, die durch Ihren Browser auf Ihrer Festplatte
                    gespeichert werden und bestimmte Einstellungen und Daten zum Austausch mit
                    unserer Website beinhalten. Unterschieden wird zwischen zwei Arten von Cookies:
                </P>
                <P gap="m">
                    Session-Cookies sind vom ersten Aufruf einer Seite unserer Website bis zum
                    Schließen des Browsers aktiv. Permanente oder temporäre Cookies, werden über
                    einen längeren Zeitraum oder unbegrenzt auf Ihrer Festplatte gespeichert.
                </P>
                <P gap="m">
                    In folgenden Bereichen unserer Website werden Cookies verwendet: Google
                    Analytics
                </P>
                <P gap="xxl">
                    Sie können das Speichern von Cookies verhindern, indem Sie Ihre
                    Browsereinstellungen entsprechend anpassen. Wie dies im Detail funktioniert,
                    entnehmen Sie bitte der Bedienungsanleitung Ihres Browsers.
                </P>
                <Headline level="h3">4.1 Log-Dateien</Headline>
                <P gap="m">
                    Bei jedem Zugriff auf Inhalte unserer Website werden automatisch Zugriffsdaten
                    über diesen Vorgang in einer Protokolldatei auf dem Server unseres Providers
                    gespeichert. Jeder Datensatz besteht dabei aus:
                </P>
                <ul>
                    <li>IP-Adresse Ihres Rechners</li>
                    <li>von Ihrem Browser für den Abruf dieser Website verwendete Port-Nummer</li>
                    <li>
                        die Adresse der von Ihnen zuletzt besuchten Seite, von wo aus Sie einem Link
                        zu der aktuellen Seite gefolgt sind (Referrer-URL)
                    </li>
                    <li>
                        Bezeichnung der Browser-Software (Name, Versionsnummer, eingestellte
                        Sprache, verwendetes Betriebssystem)
                    </li>
                    <li>Liste der vom Browser akzeptierten Dateitypen</li>
                    <li>
                        Zusätzlich kann der Browser auch Informationen liefern zu den von ihm
                        verstandenen Zeichensätzen
                    </li>
                    <li>Der genaue Zeitstempel, wann diese Seite aufgerufen wurde</li>
                </ul>
                <P gap="xxl">
                    Die gespeicherten Zugriffsdaten werden ausschließlich zu statistischen Zwecken
                    und zur Verbesserung des Onlineangebotes ausgewertet. Eine Zuordnung zu einer
                    bestimmten Person findet nicht statt und ist uns nicht möglich. Die Daten werden
                    vertraulich behandelt, nicht an Dritte weitergegeben und auch nicht mit anderen
                    Datenquellen zusammengeführt.
                </P>
                <Headline level="h3">5. Auskunftsrecht</Headline>
                <P gap="xxl">
                    Sie haben jederzeit das Recht, auf Antrag unentgeltlich über die bezüglich Ihrer
                    Person gespeicherten Daten, deren Herkunft und Empfänger sowie den Zweck der
                    Speicherung Auskunft zu erhalten. Ebenso können Sie Ihre Einwilligung zur
                    Speicherung personenbezogener Daten jederzeit widerrufen. Außerdem haben Sie das
                    Recht, Ihre gespeicherten Daten ändern, sperren oder löschen zu lassen.
                    Kontaktieren Sie in diesem Fall bitte unsere oben angegebene verantwortliche
                    Stelle.
                </P>
                <Headline level="h3">6. Links zu externen Websites</Headline>
                <P gap="xxl">
                    Unsere Website enthält Links zu externen Websites. Wir haben jedoch keinen
                    Einfluss auf die Einhaltung der Datenschutzbestimmungen seitens deren Betreiber.
                    Als Anbieter sind wir für eigene Inhalte nach den allgemeinen Gesetzen
                    verantwortlich. Zwischen unseren Inhalten und von anderen Anbietern
                    bereitgestellten Inhalten, auf die von uns verlinkt wird, ist hierbei zu
                    unterscheiden. Für fremde Inhalte, die über entsprechende Links zur Nutzung
                    zugänglich gemacht werden, übernehmen wir keinerlei Verantwortung. Für illegale,
                    fehlerhafte oder unvollständige Inhalte sowie Schäden, die durch die Nutzung
                    oder Nichtnutzung dieser Informationen entstehen, haftet alleine der Anbieter
                    der Website, auf die verwiesen wurde.
                </P>
                <Headline level="h3">Haben Sie noch Fragen oder Wünsche?</Headline>
                <P gap="xxl">
                    Ihr Vertrauen ist uns wichtig. Daher möchten wir Ihnen jederzeit Rede und
                    Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen. Weitere
                    Anregungen zum Thema Datenschutz sind uns ebenfalls sehr wichtig und jederzeit
                    willkommen. Wenn Sie Fragen haben, die Ihnen diese Datenschutzerklärung nicht
                    beantworten konnte oder wenn Sie zu einem Punkt vertiefte Informationen
                    wünschen, so helfen wir Ihnen gerne weiter. Kontaktieren Sie uns in diesem Fall
                    einfach über das Kontaktformular oder die angegebene Adresse.
                </P>
            </Section>
        </Layout>
    );
};

export default DatenschutzPage;
